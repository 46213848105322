.brand-name {
    border: 1px solid black;
    text-align: center;
    padding: 2px;
}

.brand-name-2 {
    border-top: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    padding: 2px;
}

.brand-name-3 {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    padding: 2px;
}

.brand-name-4 {
    border: 1px solid black;
    padding: 2px;
}

.attributes-4 {
    border-right: 1px solid black;
    padding: 2px;
}

.attributes-5 {
    border-bottom: 1px solid black;
    padding: 2px;
}

.attributes-6 {
    border-top: 1px solid black;
    padding: 2px;
}

.attributes-1 {
    border-right: 1px solid black;
    border-left: 1px solid black;
    padding: 2px;
}

.center-text {
    text-align: center; 
}

.attributes-1-width {
    width: 71px;
}

.attributes-2-width {
    width: 262px;
}

.attributes-3-width {
    width: 154px;
}

.right-text {
    text-align: right;
}

.attributes-4-width {
    width: 281px;
}

.date {
    font-size: 11.5px;
}